import React, { useState } from 'react'
import menu2 from "../assets/menu2.png"
export default function Menu() {
  const [showSideMenu, setShowSideMenu] = useState(false)
 
    return (
        <>
          <img src={menu2} alt="" id='menu' 
          
          
          />
        </>
    )
}
