import { Link } from 'react-router-dom'
import location from "../assets/location.png"
import emailIcon1 from "../assets/emailIcon1.png"
import phone from "../assets/phoneIcon.png"
import { useState } from 'react'
import Modal from './Modal'
import Menu from './Menu'
import whatsapp from "../assets/WhatsApp.svg.webp"
import projectImages from '../assets/projectImages'
import back from "../assets/backButton.png"
import next from "../assets/next1.png"
import image1 from "../assets/render.jpg"
import farmhouse from '../assets/farmhouse'
import farmDesign from '../assets/farmDesign'
import down from "../assets/down.png"
import React from 'react';
import image01 from "../assets/01-3.jpg"
import image2 from "../assets/02-2.jpg"
import image3 from "../assets/03-2.jpg"
import image4 from "../assets/004/003-2.jpg"
import image5 from "../assets/004/004.jpg"
import image6 from "../assets/004/007-2.jpg"
import image7 from "../assets/004/Boundary Wall .jpg"
import image8 from "../assets/v3.jpg"
import image9 from "../assets/v4.jpg"
import image10 from "../assets/Camera_03.jpg"
import image11 from "../assets/Drawing.jpg"
import image12 from "../assets/Camera_04.jpg"
import image13 from "../assets/Camera_05.jpg"
import image14 from "../assets/sehar/4) CEO Room.jpg"
import image15 from "../assets/sehar/2) MEETING ROOM.jpg"
import image16 from "../assets/sehar/5) Marketing Room.jpg"
import image17 from "../assets/sehar/1) Lobby.jpg"
import image18 from "../assets/sehar/009999.jpg"
import image19 from "../assets/sehar/3) Accounts Department.jpg"
import image20 from "../assets/sehar/6) Manager's Room.jpg"
import image21 from "../assets/sehar/10) Details.jpg"
import image22 from "../assets/sehar/11) Details.jpg"
import image23 from "../assets/nafeesa/Meeting Room.jpg"
import image24 from "../assets/nafeesa/Rejuvination Area (2).jpg"
import image25 from  "../assets/nafeesa/Featured Wall.jpg"
import image26 from "../assets/nafeesa/Rejuvination Area (1).jpg"
import image27 from "../assets/nafeesa/Reception.jpg"
import video from "../assets/video.mp4" 
import image28 from "../assets/v1.jpg"
import image29 from "../assets/v2.jpg"
import image30 from "../assets/hotelInterior/1) Lobby Lounge With Chandlier .jpg"
import image31 from "../assets/hotelInterior/2) Lobby Lounge.jpg"
import image32 from "../assets/hotelInterior/3) Seating Space.jpg"
import image33 from "../assets/hotelInterior/4) Seating Space.jpg"
import image34 from "../assets/hotelInterior/7) Reception Desk.jpg"
import image35 from "../assets/hotelInterior/10) Landscaping .jpg"
import image36 from "../assets/hotelInterior/13.png"
import image37 from "../assets/hotelInterior/infinity.jpg"
import image38 from "../assets/R4.jpg"
import image39 from "../assets/modernHouse/R3.jpg"
import image40 from "../assets/modernHouse/FINAL .jpg"
import image41 from "../assets/modernHouse/R5.jpg"
import image42 from "../assets/hotelBed/222_1 - Photo (6).jpg"
import image43 from "../assets/hotelBed/FINAL.jpg"
import image44 from "../assets/differentInterioProjects/Final Render.jpg"
import image45 from "../assets/differentInterioProjects/1.jpg"
import image46 from "../assets/differentInterioProjects/Option_02.jpg"
import image47 from "../assets/differentInterioProjects/Media Wall.jpg"
import image48 from "../assets/differentInterioProjects/Final_Render_01 2.jpg"
import image49 from "../assets/differentInterioProjects/Media Wall.jpg"
import image50 from "../assets/Living Room 2.jpg"
import image51 from "../assets/Living Room 3.jpg"
import image52 from "../assets/Living Room 1.jpg"
import image53 from "../assets/Living Room 4.jpg"
import image54 from "../assets/livingroom/Render 01.jpg"
import image55 from "../assets/livingroom/Render 03.jpg"
import image56 from "../assets/livingroom/Render 02.jpg"
import image57 from "../assets/drawingroom/Scene 2.jpg"
import image58 from "../assets/drawingroom/Scene 1.jpg"
import image59 from "../assets/drawingroom/Final_Render_01.jpg"
import image60 from "../assets/bedroomsandwashrooms/Scene 1 (2).jpg"
import image61 from "../assets/bedroomsandwashrooms/Scene 2.jpg"
import image62 from "../assets/bedroomsandwashrooms/Bathroom 5.jpg"
import image63 from "../assets/differentInterioProjects/Final_Render copy.jpg"
import image64 from "../assets/differentInterioProjects/Gym Scene 2.jpg"
import image65 from "../assets/differentInterioProjects/Scene 1.jpg"
import image66 from "../assets/differentInterioProjects/Scene 2.jpg"
import image67 from "../assets/differentInterioProjects/Camera 02.jpg"
import image68 from "../assets/differentInterioProjects/Camera 01.jpg"
import image69 from "../assets/hotelBed/R.1.jpg"
import image70 from "../assets/hotelBed/R.3.jpg"
import image71 from "../assets/hotelBed/R.2.jpg"
import image72 from "../assets/hotelBed/R.4.jpg"
import image73 from "../assets/hotelBed/R.5.jpg"
import image74 from "../assets/hotelBed/R.6.jpg"
import image75 from "../assets/hotelBed/R.7.jpg"
import image76 from "../assets/hotelBed/R.8.jpg"
import image77 from "../assets/hotelbed1/Final 1.jpg"
import image78 from "../assets/hotelbed1/Final 2.jpg"
import image79 from "../assets/hotelbed1/Final 3.jpg"
import image80 from "../assets/hotelbed1/Final 5.png"
import image81 from "../assets/hotelbed1/Final 6.png"
import image82 from "../assets/hotelbed1/Final 7.png"
import image83 from "../assets/Benergy/Work Space.jpg"
import image84 from "../assets/Benergy/Approved layout 1.jpg"
import image85 from "../assets/Benergy/Approved layout.jpg"
import image86 from "../assets/Benergy/Camera_02.jpg"
import image87 from "../assets/Benergy/Display Wall.jpg"
import image88 from "../assets/Benergy/Manager's Room Other proposal.jpg"
import image89 from "../assets/Benergy/Managers Room.jpg"
import image90 from "../assets/Benergy/test render.jpg"
import image91 from "../assets/Benergy/Birds eye view.jpg"
import image92 from "../assets/bedroomsandwashrooms/Bathroom 4.jpg"
import image93 from "../assets/bedroomsandwashrooms/Bathroom 3.jpg"
import image94 from "../assets/bedroomsandwashrooms/Bathroom 2.jpg"
import image95 from  "../assets/bedroomsandwashrooms/Scene 1 (2).jpg"
import image96 from "../assets/bedroomsandwashrooms/Camera_02_Final_Render.jpg"
import image97 from "../assets/bedroomsandwashrooms/Scene 1 (1).jpg"
import image98 from "../assets/bedroomsandwashrooms/Scene 2 (1).jpg"
import image99 from "../assets/drawingroom/Final_Render_01.jpg"
import image100 from "../assets/bedroomsandwashrooms/Scene 1 (1).jpg"
import image101 from "../assets/bedroomsandwashrooms/Scene 2 (1).jpg"
import image102 from "../assets/Living Room 1.jpg"
import image103 from "../assets/Living Room 3.jpg"
import image104 from "../assets/Drawing.jpg"
import image105 from "../assets/Camera_03.jpg"
import image106 from "../assets/bedroomsandwashrooms/Scene 1.jpg"
import image107 from "../assets/bedroomsandwashrooms/Scene 2.jpg"
import image108 from "../assets/livingroom/Render 01.jpg"
import image109 from "../assets/livingroom/Render 03.jpg"
import image110 from "../assets/advertisement1.jpg"
import image111 from "../assets/sahq.jpg"
import image112 from "../assets/Executive Room Interior/Final 1.jpg"
import image113 from "../assets/Executive Room Interior/Final 2.jpg"
import image114 from "../assets/Executive Room Interior/Final 3.jpg"
import image115 from "../assets/Executive Room Interior/Final 4.jpg"
import image116 from "../assets/Executive Room Interior/Proposal (1).jpg"
import image117 from "../assets/Executive Room Interior/Proposal (222).jpg"
import image118 from "../assets/farmhouse/1.png"
import image119 from "../assets/farmhouse/2.png"
import image120 from "../assets/farmhouse/3.png"
import image121 from "../assets/farmhouse/4.png"
import image122 from "../assets/farmhouse/5.png"
import image123 from "../assets/farmhouse/6.png"
import image124 from "../assets/farmhouse/7.png"
import image125 from "../assets/farmhouse/8.png"
import image126 from "../assets/farmhouse/9.png"
import image127 from "../assets/farmhouse/10.png"
import image128 from "../assets/farmhouse/11.png"
import image129 from "../assets/farmhouse/12.png"
import image130 from "../assets/farmhouse/13.png"




export default function Projects() {
  const [state, setState] = useState("ALL")
  const [RoomLiving, setState15] = useState(0)
  const [ModernLounge, setState16] = useState(0)
  const [DrawingRoom, setState17] = useState(0)
  const [ApartDesign, setState18] = useState(0)
  const [Gym, setState19] = useState(0)
  const [Entrance, setState20] = useState(0)
  const [Wardrobe, setState21] = useState(0)
  const [BedroomHotel, setState22] = useState(0)
  const [ModernBed, setState23] = useState(0)
  const [Benergy, setState24] = useState(0)
  const [Bathroom, setState25] = useState(0)
  const [Apartment, setState26] = useState(0)
  const [HouseInterior, setState27] = useState(0)
  const [Luxury, setState28] = useState(0)
  const [SAHQ, setState29] = useState(0)
  const [Executive, setState30] = useState(0)
  const [Farmhouse, setState31] = useState(0)
  const [FarmDesign, setState32] = useState(0)



  window.addEventListener("scroll", () => {
    const topBorder = document.getElementById('navbar-container').getBoundingClientRect().top;
    topBorder >= 0
      ? document.getElementById("navbar").classList.remove("fixed")
      : document.getElementById("navbar").classList.add("fixed");


  })


  window.scrollTo(0, 0);


  const handleNavLinkClick = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <header id='navbar-container'>
        <nav id="navbar">
          <div className="logo">
            <h1><Link to="/">A / 75</Link></h1>
          </div>
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
            <li >
              <Link to="/services"

                onMouseOver={() => {
                  setTimeout(() => {
                    document.getElementById('menuLinks').classList.add('show')
                  }, 600)



                }}

              >
                EXPERTISE

              </Link>



            </li>
            <li><Link to="/projects">PROJECTS</Link></li>



            <li><Link to="/contact">CONTACT</Link></li>
            <li><Modal /></li>
            <li onClick={() => {
              if (document.getElementById('sideBarContainer').style.display == "none") {
                setTimeout(() => {
                  document.getElementById('sideBarContainer').classList.add('show')
                  document.getElementById('sideBarContainer').style.display = "block"
                }, 300)

              }
              else {
                document.getElementById('sideBarContainer').style.display = "none"
                document.getElementById('sideMenu').style.display = "block"


              }

            }} id='sideMenu'>
              <Menu />
            </li>

            <li onClick={() => {
              if (document.getElementById('navLinks').style.display === "none" || document.getElementById('navLinks').style.display === "") {
                document.getElementById('navLinks').style.display = "block"
              } else {
                document.getElementById('navLinks').style.display = "none"
              }
              document.getElementById('spanLink').style.display = "none"

            }}>
              <div></div>
              <div></div>
              <div></div>
              <li id="navLinks">
                <Link to="/" onClick={handleNavLinkClick}>HOME</Link>
                <Link to="/about" onClick={handleNavLinkClick}>ABOUT</Link>

                <Link to="/services" onClick={handleNavLinkClick}>EXPERTISE</Link>
                <span id="serviceSelector" onClick={(event) => {
                  event.stopPropagation()
                  document.getElementById('spanLink').style.display = "block"
                }}>
                  <img src={down} alt="" />
                  <span id='spanLink'>
                    <Link to="/services/architecture">ARCHITECTURE</Link>
                    <Link to="/services/interiors">INTERIORS</Link>
                    <Link to="/services/landscape">LANDSCAPE DESIGN</Link>
                    <Link to="/services/urban">URBAN DESIGN + PLANNING</Link>
                    <Link to="/services/commercial">COMMERCIAL</Link>
                    <Link to="/services/facade-design">FACADE DESIGN</Link>
                    <Link to="/services/visualization">VISUALIZATION</Link>
                    <Link to="/services/hospitality">HOSPITALITY</Link>
                    <Link to="/services/workplace">WORKPLACE</Link>
                    <Link to="/services/res">RESIDENTIAL</Link>
                    <Link to="/services/construction">CONSTRUCTION</Link>
                  </span>

                </span>
                <Link to="/projects">PROJECTS</Link>
                <Link to="/contact" onClick={handleNavLinkClick}>CONTACT</Link>
              </li>

            </li>

          </ul>
        </nav>

      </header>

      <ul id='menuLinks' className='menuList'>
        <li><Link to="/services/architecture">ARCHITECTURE</Link></li>
        <li><Link to="/services/interiors">INTERIORS</Link></li>
        <li><Link to="/services/landscape">LANDSCAPE DESIGN</Link></li>
        <li><Link to="/services/urban">URBAN DESIGN + PLANNING</Link></li>
        <li><Link to="/services/commercial">COMMERCIAL</Link></li>
        <li><Link to="/services/facade-design">FACADE DESIGN</Link></li>
        <li><Link to="/services/visualization">VISUALIZATION</Link></li>
        <li><Link to="/services/hospitality">HOSPITALITY</Link></li>
        <li><Link to="/services/workplace">WORKPLACE</Link></li>
        <li><Link to="/services/res">RESIDENTIAL</Link></li>
        <li><Link to="/services/construction">CONSTRUCTION</Link></li>
      </ul>

      <div id="sideBarContainer">
        <span id='closeSideMenu' onClick={() => {
          document.getElementById('sideBarContainer').style.display = "none"
        }}>X</span>
        <div className="sideBarLogo">
          <h1>A  / <span>75</span></h1>
        </div>
        <div className="sideBarSocialLinks">
          <a href="https://www.facebook.com/profile.php?id=61551238487509" target='_blank' id='facebook'>

            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 12.05C19.9813 10.5255 19.5273 9.03809 18.6915 7.76295C17.8557 6.48781 16.673 5.47804 15.2826 4.85257C13.8921 4.2271 12.3519 4.01198 10.8433 4.23253C9.33473 4.45309 7.92057 5.10013 6.7674 6.09748C5.61422 7.09482 4.77005 8.40092 4.3343 9.86195C3.89856 11.323 3.88938 12.8781 4.30786 14.3442C4.72634 15.8103 5.55504 17.1262 6.69637 18.1371C7.83769 19.148 9.24412 19.8117 10.75 20.05V14.38H8.75001V12.05H10.75V10.28C10.7037 9.86846 10.7483 9.45175 10.8807 9.05931C11.0131 8.66687 11.23 8.30827 11.5161 8.00882C11.8022 7.70936 12.1505 7.47635 12.5365 7.32624C12.9225 7.17612 13.3368 7.11255 13.75 7.14003C14.3498 7.14824 14.9482 7.20173 15.54 7.30003V9.30003H14.54C14.3676 9.27828 14.1924 9.29556 14.0276 9.35059C13.8627 9.40562 13.7123 9.49699 13.5875 9.61795C13.4627 9.73891 13.3667 9.88637 13.3066 10.0494C13.2464 10.2125 13.2237 10.387 13.24 10.56V12.07H15.46L15.1 14.4H13.25V20C15.1399 19.7011 16.8601 18.7347 18.0985 17.2761C19.3369 15.8175 20.0115 13.9634 20 12.05Z" fill="#000000" />
            </svg>
          </a>
          <a href="https://www.instagram.com/atelier75.co/?igshid=MjEwN2IyYWYwYw%3D%3D" target='_blank' id='insta'>
            <svg width="800px" height="800px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">

              <g fill="none" fillRule="evenodd">

                <path d="m0 0h32v32h-32z" />

                <path d="m23.6190476.13999994.2518496.00400082c4.4330352.13698982 7.9850489 3.77162206 7.9891029 8.23707871v15.23796813l-.0040009.2518496c-.1369898 4.4330352-3.771622 7.9850489-8.2370787 7.9891029h-15.23796812l-.25184954-.0040009c-4.43303524-.1369898-7.98504891-3.771622-7.9891029-8.2370787v-15.23796812l.00400082-.25184954c.13698982-4.43303524 3.77162206-7.98504891 8.23707871-7.9891029zm0 2.00571434h-15.23809522l-.2285857.00411224c-3.33773425.12028135-6.0066524 2.86402399-6.0066524 6.23112586v15.23809522l.00411224.2285857c.12028135 3.3377343 2.86402399 6.0066524 6.23112586 6.0066524h15.23809522l.2285857-.0041122c3.3377343-.1202814 6.0066524-2.864024 6.0066524-6.2311259v-15.23809522l-.0041122-.2285857c-.1202814-3.33773425-2.864024-6.0066524-6.2311259-6.0066524zm-7.6191758 4.85142851.2653486.00407526c4.8475552.14463612 8.7331682 4.11734025 8.7376368 8.99891015 0 4.9720125-4.0307165 9.002729-9.0028572 9.002729s-9.00285716-4.0307165-9.00285716-9.0028572c0-4.8833525 3.88804756-8.8586028 8.73741066-8.99901883zm.0001282 2.00571437c-3.8644153 0-6.99714284 3.13272754-6.99714284 6.99714284s3.13272754 6.9971428 6.99714284 6.9971428 6.9971428-3.1327275 6.9971428-6.9971428-3.1327275-6.99714284-6.9971428-6.99714284zm8.7619048-3.91047622c.7642569 0 1.3838095.61955263 1.3838095 1.38380953s-.6195526 1.38380953-1.3838095 1.38380953c-.764257 0-1.3838096-.61955263-1.3838096-1.38380953s.6195526-1.38380953 1.3838096-1.38380953z" fill="#000000" fillRule="nonzero" />

              </g>

            </svg>
          </a>

          <a href="https://www.linkedin.com/in/saad-bashir-28b3ba116/" target='_blank' id='linkedin'>
            <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
              viewBox="-143 145 512 512" xmlSpace="preserve">
              <path d="M-143,145v512h512V145H-143z M41.4,508.1H-8.5V348.4h49.9V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7
c0-15.8,12.1-27.7,30.5-27.7c18.4,0,29.7,11.9,30.1,27.7C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6
c0-21.6-8.8-36.4-28.3-36.4c-14.9,0-23.2,10-27,19.6c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1
c3.3-11,21.2-26.6,49.8-26.6c35.5,0,63.3,23,63.3,72.4V508.1z"/>
            </svg>
          </a>
        </div>
        <h4>CONTACT US</h4>
        <div className="sideBarLocation">
          <div>
            <img src={location} alt="" />
          </div>
          <div>
            <p>602-D Eden Towers, Main Boulevard Gulberg III <br /> Lahore, Pakistan</p>
          </div>

        </div>
        <div className="sideBarLocation">
          <div>
            <img src={location} alt="" />
          </div>
          <div>
            <p>Bayswater Tower Office # 408 <br></br>Business Bay Dubai, UAE</p>
          </div>

        </div>

        <div className="sideBarEmail">
          <div>
            <img src={emailIcon1} alt="" />
          </div>
          <dir>
            <p>info@atelier75.co</p>
          </dir>
        </div>
        <div className="sideBarPhone">
          <div>
            <img src={phone} alt="" />
          </div>
          <dir>
            <p>+92 321 2556912 (Lahore,Pakistan)</p>
          </dir>
        </div>
        <div className="sideBarPhone">
          <div>
            <img src={phone} alt="" />
          </div>
          <dir>
            <p>+353 89 444 9969 (Dublin, Ireland)</p>
          </dir>
        </div>
        <div className="sideBarPhone">
          <div>
            <img src={phone} alt="" />
          </div>
          <dir>
            <p>04-4327307 (Dubai, UAE)</p>
          </dir>
        </div>
      </div>







      <div className="portfolio" id='pro' onMouseOver={() => {
        document.getElementById('menuLinks').classList.remove('show')
      }}>
        <div>
          <h1>Projects</h1>
        </div>
        <div>
          <p>A few selected designs by Atelier 75 have been displayed below. Use the menu to switch between Architecture, Interior Design, 3D Visualization.</p>
        </div>

      </div>
      <div className="projects" onMouseOver={() => {
        document.getElementById('menuLinks').classList.remove('show')
      }}>
        <div className="select"
          onMouseLeave={() => {
            document.getElementById('all').style.display = "none"
            document.getElementById('architecture').style.display = "none"
            document.getElementById('interior').style.display = "none"
            document.getElementById('threed').style.display = "none"
          }}
          onClick={() => {
            let a = document.getElementById('all').style.display
            let b = document.getElementById('architecture').style.display
            let c = document.getElementById('interior').style.display
            let d = document.getElementById('threed').style.display
            if (a == "none" && b == "none" && c == "none" && d == "none") {
              document.getElementById('all').style.display = "block"
              document.getElementById('architecture').style.display = "block"
              document.getElementById('interior').style.display = "block"
              document.getElementById('threed').style.display = "block"
            } else {
              document.getElementById('all').style.display = "none"
              document.getElementById('architecture').style.display = "none"
              document.getElementById('interior').style.display = "none"
              document.getElementById('threed').style.display = "none"
            }

          }}
        >
          <div id='change' onMouseEnter={() => {
            setTimeout(() => {
              document.getElementById('all').style.display = "block"
              document.getElementById('architecture').style.display = "block"
              document.getElementById('interior').style.display = "block"
              document.getElementById('threed').style.display = "block"
            }, 400);

          }}
          >ALL</div>
          <div id='all' onClick={() => {
            document.getElementById('change').innerHTML = "ALL"
            setState(document.getElementById('change').innerHTML)
          }}
          >ALL</div>
          <div id='architecture' onClick={() => {
            document.getElementById('change').innerHTML = "ARCHITECTURE"
            setState(document.getElementById('change').innerHTML)
          }}
          >ARCHITECTURE</div>
          <div id='interior' onClick={() => {
            document.getElementById('change').innerHTML = "INTERIORS"
            setState(document.getElementById('change').innerHTML)
          }}
          >INTERIORS</div>
          <div id='threed' onClick={() => {
            document.getElementById('change').innerHTML = "3D VISUALIZATION"
            setState(document.getElementById('change').innerHTML)
          }}
          >VISUALIZATION</div>

        </div>


      </div>


      <div className="pics" onMouseOver={() => {
        document.getElementById('menuLinks').classList.remove('show')
      }}>
        {
          projectImages.map(images => {
            let text = images.text
            if (state === "ALL") {
              return (

                <div>
                  <img src={images.src} alt="" onMouseOver={() => {
                    if (images.text === text) {
                      document.getElementById(images.id).style.display = "block"
                    }
                  }} onMouseLeave={() => {
                    document.getElementById(images.id).style.display = "none"
                  }}
                    onClick={() => {
                      if (images.text === "VILLA IN BOSNIA") {
                        document.getElementById('projectDetails').style.display = "block"
                        document.getElementById('projectDetails').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "RESIDENCE 159-H") {
                        document.getElementById('projectDetails1').style.display = "block"
                        document.getElementById('projectDetails1').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "MODERN BEDROOM") {
                        document.getElementById('projectDetails2').style.display = "block"
                        document.getElementById('projectDetails2').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "LOUNGE AND DINING SPACE") {
                        document.getElementById('projectDetails3').style.display = "block"
                        document.getElementById('projectDetails3').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "CORPORATE OFFICE INTERIOR") {
                        document.getElementById('projectDetails4').style.display = "block"
                        document.getElementById('projectDetails4').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "TEXTILE OFFICE DESIGN") {
                        document.getElementById('projectDetails5').style.display = "block"
                        document.getElementById('projectDetails5').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "BEDROOM INTERIOR") {
                        document.getElementById('projectDetails6').style.display = "block"
                        document.getElementById('projectDetails6').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "HOTEL INTERIOR") {
                        document.getElementById('projectDetails7').style.display = "block"
                        document.getElementById('projectDetails7').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "MODERN HOUSE") {
                        document.getElementById('projectDetails8').style.display = "block"
                        document.getElementById('projectDetails8').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "MODERN VILLA") {
                        document.getElementById('projectDetails9').style.display = "block"
                        document.getElementById('projectDetails9').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "RESTAURANT INTERIOR") {
                        document.getElementById('projectDetails10').style.display = "block"
                        document.getElementById('projectDetails10').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "LIVING ROOM") {
                        document.getElementById('projectDetails11').style.display = "block"
                        document.getElementById('projectDetails11').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "BEDROOM INTERIOR DESIGN") {
                        document.getElementById('projectDetails12').style.display = "block"
                        document.getElementById('projectDetails12').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "BEDROOM DESIGN") {
                        document.getElementById('projectDetails13').style.display = "block"
                        document.getElementById('projectDetails13').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "MODERN LIVING ROOM") {
                        document.getElementById('projectDetails14').style.display = "block"
                        document.getElementById('projectDetails14').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "MODERN LOUNGE INTERIOR") {
                        document.getElementById('projectDetails15').style.display = "block"
                        document.getElementById('projectDetails15').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "DRAWING ROOM") {
                        document.getElementById('projectDetails16').style.display = "block"
                        document.getElementById('projectDetails16').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "APARTMENT DESIGN") {
                        document.getElementById('projectDetails17').style.display = "block"
                        document.getElementById('projectDetails17').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "GYM INTERIOR") {
                        document.getElementById('projectDetails18').style.display = "block"
                        document.getElementById('projectDetails18').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "ENTRANCE LOBBY") {
                        document.getElementById('projectDetails19').style.display = "block"
                        document.getElementById('projectDetails19').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "WALK-IN WARDROBE") {
                        document.getElementById('projectDetails20').style.display = "block"
                        document.getElementById('projectDetails20').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "HOTEL BEDROOM") {
                        document.getElementById('projectDetails21').style.display = "block"
                        document.getElementById('projectDetails21').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "A MODERN BEDROOM INTERIOR") {
                        document.getElementById('projectDetails22').style.display = "block"
                        document.getElementById('projectDetails22').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "OFFICE INTERIOR") {
                        document.getElementById('projectDetails23').style.display = "block"
                        document.getElementById('projectDetails23').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "MASTER BATHROOM DESIGN") {
                        document.getElementById('projectDetails24').style.display = "block"
                        document.getElementById('projectDetails24').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "LUXURY APARTMENT DESIGN") {
                        document.getElementById('projectDetails25').style.display = "block"
                        document.getElementById('projectDetails25').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "MODERN HOUSE INTERIOR") {
                        document.getElementById('projectDetails26').style.display = "block"
                        document.getElementById('projectDetails26').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "LUXURY APARTMENT INTERIOR") {
                        document.getElementById('projectDetails27').style.display = "block"
                        document.getElementById('projectDetails27').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "SA HEADQUARTERS") {
                        document.getElementById('projectDetails28').style.display = "block"
                        document.getElementById('projectDetails28').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                        document.getElementById('projectDetails29').style.display = "block"
                        document.getElementById('projectDetails29').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else if (images.text === "FARMHOUSE INTERIOR") {
                        document.getElementById('projectDetails30').style.display = "block"
                        document.getElementById('projectDetails30').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      } else {
                        document.getElementById('projectDetails31').style.display = "block"
                        document.getElementById('projectDetails31').scrollTop = 0
                        document.getElementById('navbar').style.display = "none"
                        document.getElementById('wapp').style.display = "none"
                      }
                    }}
                    onTouchStart={() => {
                      if (images.text === "VILLA IN BOSNIA") {
                        document.getElementById(images.id).style.display = "block"
                      } else if (images.text === "RESIDENCE 159-H") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "MODERN BEDROOM") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "MODERN BEDROOM") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "LOUNGE AND DINING SPACE") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "CORPORATE OFFICE INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "TEXTILE OFFICE DESIGN") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "BEDROOM INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "HOTEL INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "MODERN HOUSE") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "MODERN VILLA") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "RESTAURANT INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "LIVING ROOM") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "BEDROOM INTERIOR DESIGN") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "BEDROOM DESIGN") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "MODERN LIVING ROOM") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "MODERN LOUNGE INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "DRAWING ROOM") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "APARTMENT DESIGN") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "GYM INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "ENTRANCE LOBBY") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "WALK-IN WARDROBE") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "HOTEL BEDROOM") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "A MODERN BEDROOM INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "OFFICE INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "MASTER BATHROOM DESIGN") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "LUXURY APARTMENT DESIGN") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "MODERN HOUSE INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "LUXURY APARTMENT INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "SA HEADQUARTERS") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      } else if (images.text === "FARMHOUSE INTERIOR") {
                        document.getElementById(images.id).style.display = "block"
                      } else {
                        document.getElementById(images.id).style.display = "block"
                      }
                    }}
                    onTouchEnd={() => {
                      if (images.text === "VILLA IN BOSNIA") {
                        document.getElementById(images.id).style.display = "none"
                      } else if (images.text === "RESIDENCE 159-H") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "MODERN BEDROOM") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "MODERN BEDROOM") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "LOUNGE AND DINING SPACE") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "CORPORATE OFFICE INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "TEXTILE OFFICE DESIGN") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "BEDROOM INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "HOTEL INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "MODERN HOUSE") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "MODERN VILLA") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "RESTAURANT INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      } else if (images.text === "BEDROOM INTERIOR DESIGN") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "LIVING ROOM") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "BEDROOM INTERIOR DESIGN") {
                        document.getElementById(images.id).style.display = "block"
                      }
                      else if (images.text === "BEDROOM DESIGN") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "MODERN LIVING ROOM") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "MODERN LOUNGE INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "DRAWING ROOM") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "APARTMENT DESIGN") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "GYM INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "ENTRANCE LOBBY") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "WALK-IN WARDROBE") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "HOTEL BEDROOM") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "A MODERN BEDROOM INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "OFFICE INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "MASTER BATHROOM DESIGN") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "LUXURY APARTMENT DESIGN") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "MODERN HOUSE INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "LUXURY APARTMENT INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "SA HEADQUARTERS") {
                        document.getElementById(images.id).style.display = "none"
                      }
                      else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      } else if (images.text === "FARMHOUSE INTERIOR") {
                        document.getElementById(images.id).style.display = "none"
                      } else {
                        document.getElementById(images.id).style.display = "none"
                      }
                    }}
                  />

                  <div id={images.id} className='text'>
                    <h1>{images.text}</h1>
                  </div>

                </div>
              )
            } else if (state === "ARCHITECTURE" && images.type === "architecture") {
              return (
                <>
                  <div>
                    <img src={images.src} alt="" onMouseOver={() => {
                      if (images.text === text) {
                        document.getElementById(images.id).style.display = "block"
                      }
                    }} onMouseLeave={() => {
                      document.getElementById(images.id).style.display = "none"
                    }}
                      onClick={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById('projectDetails').style.display = "block"
                          document.getElementById('projectDetails').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById('projectDetails1').style.display = "block"
                          document.getElementById('projectDetails1').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN BEDROOM") {
                          document.getElementById('projectDetails2').style.display = "block"
                          document.getElementById('projectDetails2').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById('projectDetails3').style.display = "block"
                          document.getElementById('projectDetails3').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById('projectDetails4').style.display = "block"
                          document.getElementById('projectDetails4').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById('projectDetails5').style.display = "block"
                          document.getElementById('projectDetails5').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById('projectDetails6').style.display = "block"
                          document.getElementById('projectDetails6').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById('projectDetails7').style.display = "block"
                          document.getElementById('projectDetails7').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN HOUSE") {
                          document.getElementById('projectDetails8').style.display = "block"
                          document.getElementById('projectDetails8').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN VILLA") {
                          document.getElementById('projectDetails9').style.display = "block"
                          document.getElementById('projectDetails9').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById('projectDetails10').style.display = "block"
                          document.getElementById('projectDetails10').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LIVING ROOM") {
                          document.getElementById('projectDetails11').style.display = "block"
                          document.getElementById('projectDetails11').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById('projectDetails12').style.display = "block"
                          document.getElementById('projectDetails12').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById('projectDetails13').style.display = "block"
                          document.getElementById('projectDetails13').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById('projectDetails14').style.display = "block"
                          document.getElementById('projectDetails14').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById('projectDetails15').style.display = "block"
                          document.getElementById('projectDetails15').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "DRAWING ROOM") {
                          document.getElementById('projectDetails16').style.display = "block"
                          document.getElementById('projectDetails16').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById('projectDetails17').style.display = "block"
                          document.getElementById('projectDetails17').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "GYM INTERIOR") {
                          document.getElementById('projectDetails18').style.display = "block"
                          document.getElementById('projectDetails18').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById('projectDetails19').style.display = "block"
                          document.getElementById('projectDetails19').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById('projectDetails20').style.display = "block"
                          document.getElementById('projectDetails20').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById('projectDetails21').style.display = "block"
                          document.getElementById('projectDetails21').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById('projectDetails22').style.display = "block"
                          document.getElementById('projectDetails22').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById('projectDetails23').style.display = "block"
                          document.getElementById('projectDetails23').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById('projectDetails24').style.display = "block"
                          document.getElementById('projectDetails24').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById('projectDetails25').style.display = "block"
                          document.getElementById('projectDetails25').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById('projectDetails26').style.display = "block"
                          document.getElementById('projectDetails26').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById('projectDetails27').style.display = "block"
                          document.getElementById('projectDetails27').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById('projectDetails28').style.display = "block"
                          document.getElementById('projectDetails28').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById('projectDetails29').style.display = "block"
                          document.getElementById('projectDetails29').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById('projectDetails30').style.display = "block"
                          document.getElementById('projectDetails30').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else {
                          document.getElementById('projectDetails31').style.display = "block"
                          document.getElementById('projectDetails31').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        }
                      }}

                      onTouchStart={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById(images.id).style.display = "block"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN HOUSE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN VILLA") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LIVING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "DRAWING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "GYM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        } else {
                          document.getElementById(images.id).style.display = "block"
                        }
                      }}
                      onTouchEnd={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById(images.id).style.display = "none"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN HOUSE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN VILLA") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LIVING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "DRAWING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "GYM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        } else {
                          document.getElementById(images.id).style.display = "none"
                        }
                      }}

                    />
                    <div id={images.id}>
                      <h1>{images.text}</h1>
                    </div>
                  </div>

                </>
              )
            }
            else if (state === "INTERIORS" && images.type === "interior") {
              return (
                <>
                  <div>
                    <img src={images.src} alt="" onMouseOver={() => {
                      if (images.text === text) {
                        document.getElementById(images.id).style.display = "block"
                      }
                    }} onMouseLeave={() => {
                      document.getElementById(images.id).style.display = "none"
                    }}
                      onClick={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById('projectDetails').style.display = "block"
                          document.getElementById('projectDetails').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById('projectDetails1').style.display = "block"
                          document.getElementById('projectDetails1').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN BEDROOM") {
                          document.getElementById('projectDetails2').style.display = "block"
                          document.getElementById('projectDetails2').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById('projectDetails3').style.display = "block"
                          document.getElementById('projectDetails3').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById('projectDetails4').style.display = "block"
                          document.getElementById('projectDetails4').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById('projectDetails5').style.display = "block"
                          document.getElementById('projectDetails5').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById('projectDetails6').style.display = "block"
                          document.getElementById('projectDetails6').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById('projectDetails7').style.display = "block"
                          document.getElementById('projectDetails7').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN HOUSE") {
                          document.getElementById('projectDetails8').style.display = "block"
                          document.getElementById('projectDetails8').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN VILLA") {
                          document.getElementById('projectDetails9').style.display = "block"
                          document.getElementById('projectDetails9').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById('projectDetails10').style.display = "block"
                          document.getElementById('projectDetails10').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LIVING ROOM") {
                          document.getElementById('projectDetails11').style.display = "block"
                          document.getElementById('projectDetails11').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById('projectDetails12').style.display = "block"
                          document.getElementById('projectDetails12').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById('projectDetails13').style.display = "block"
                          document.getElementById('projectDetails13').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById('projectDetails14').style.display = "block"
                          document.getElementById('projectDetails14').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById('projectDetails15').style.display = "block"
                          document.getElementById('projectDetails15').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "DRAWING ROOM") {
                          document.getElementById('projectDetails16').style.display = "block"
                          document.getElementById('projectDetails16').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById('projectDetails17').style.display = "block"
                          document.getElementById('projectDetails17').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "GYM INTERIOR") {
                          document.getElementById('projectDetails18').style.display = "block"
                          document.getElementById('projectDetails18').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById('projectDetails19').style.display = "block"
                          document.getElementById('projectDetails19').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById('projectDetails20').style.display = "block"
                          document.getElementById('projectDetails20').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById('projectDetails21').style.display = "block"
                          document.getElementById('projectDetails21').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById('projectDetails22').style.display = "block"
                          document.getElementById('projectDetails22').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById('projectDetails23').style.display = "block"
                          document.getElementById('projectDetails23').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById('projectDetails24').style.display = "block"
                          document.getElementById('projectDetails24').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById('projectDetails25').style.display = "block"
                          document.getElementById('projectDetails25').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById('projectDetails26').style.display = "block"
                          document.getElementById('projectDetails26').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById('projectDetails27').style.display = "block"
                          document.getElementById('projectDetails27').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById('projectDetails28').style.display = "block"
                          document.getElementById('projectDetails28').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById('projectDetails29').style.display = "block"
                          document.getElementById('projectDetails29').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById('projectDetails30').style.display = "block"
                          document.getElementById('projectDetails30').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else {
                          document.getElementById('projectDetails31').style.display = "block"
                          document.getElementById('projectDetails31').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        }
                      }}
                      onTouchStart={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById(images.id).style.display = "block"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN HOUSE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN VILLA") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LIVING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "DRAWING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "GYM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        } else {
                          document.getElementById(images.id).style.display = "block"
                        }
                      }}
                      onTouchEnd={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById(images.id).style.display = "none"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN HOUSE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN VILLA") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LIVING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "DRAWING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "GYM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        } else {
                          document.getElementById(images.id).style.display = "none"
                        }
                      }}

                    />



                    <div id={images.id}>
                      <h1>{images.text}</h1>
                    </div>
                  </div>

                </>
              )
            }
            else if (state === "3D VISUALIZATION") {
              return (
                <>
                  <div>
                    <img src={images.src} alt="" onMouseOver={() => {
                      if (images.text === text) {
                        document.getElementById(images.id).style.display = "block"
                      }
                    }} onMouseLeave={() => {
                      document.getElementById(images.id).style.display = "none"
                    }}
                      onClick={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById('projectDetails').style.display = "block"
                          document.getElementById('projectDetails').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById('projectDetails1').style.display = "block"
                          document.getElementById('projectDetails1').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN BEDROOM") {
                          document.getElementById('projectDetails2').style.display = "block"
                          document.getElementById('projectDetails2').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById('projectDetails3').style.display = "block"
                          document.getElementById('projectDetails3').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById('projectDetails4').style.display = "block"
                          document.getElementById('projectDetails4').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById('projectDetails5').style.display = "block"
                          document.getElementById('projectDetails5').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById('projectDetails6').style.display = "block"
                          document.getElementById('projectDetails6').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById('projectDetails7').style.display = "block"
                          document.getElementById('projectDetails7').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN HOUSE") {
                          document.getElementById('projectDetails8').style.display = "block"
                          document.getElementById('projectDetails8').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN VILLA") {
                          document.getElementById('projectDetails9').style.display = "block"
                          document.getElementById('projectDetails9').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById('projectDetails10').style.display = "block"
                          document.getElementById('projectDetails10').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LIVING ROOM") {
                          document.getElementById('projectDetails11').style.display = "block"
                          document.getElementById('projectDetails11').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById('projectDetails12').style.display = "block"
                          document.getElementById('projectDetails12').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById('projectDetails13').style.display = "block"
                          document.getElementById('projectDetails13').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById('projectDetails14').style.display = "block"
                          document.getElementById('projectDetails14').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById('projectDetails15').style.display = "block"
                          document.getElementById('projectDetails15').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "DRAWING ROOM") {
                          document.getElementById('projectDetails16').style.display = "block"
                          document.getElementById('projectDetails16').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById('projectDetails17').style.display = "block"
                          document.getElementById('projectDetails17').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "GYM INTERIOR") {
                          document.getElementById('projectDetails18').style.display = "block"
                          document.getElementById('projectDetails18').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById('projectDetails19').style.display = "block"
                          document.getElementById('projectDetails19').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById('projectDetails20').style.display = "block"
                          document.getElementById('projectDetails20').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById('projectDetails21').style.display = "block"
                          document.getElementById('projectDetails21').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById('projectDetails22').style.display = "block"
                          document.getElementById('projectDetails22').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById('projectDetails23').style.display = "block"
                          document.getElementById('projectDetails23').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById('projectDetails24').style.display = "block"
                          document.getElementById('projectDetails24').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById('projectDetails25').style.display = "block"
                          document.getElementById('projectDetails25').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById('projectDetails26').style.display = "block"
                          document.getElementById('projectDetails26').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById('projectDetails27').style.display = "block"
                          document.getElementById('projectDetails27').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById('projectDetails28').style.display = "block"
                          document.getElementById('projectDetails28').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById('projectDetails29').style.display = "block"
                          document.getElementById('projectDetails29').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById('projectDetails30').style.display = "block"
                          document.getElementById('projectDetails30').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        } else {
                          document.getElementById('projectDetails31').style.display = "block"
                          document.getElementById('projectDetails31').scrollTop = 0
                          document.getElementById('navbar').style.display = "none"
                          document.getElementById('wapp').style.display = "none"
                        }
                      }}
                      onTouchStart={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById(images.id).style.display = "block"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN HOUSE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN VILLA") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LIVING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "DRAWING ROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "GYM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "block"
                        } else {
                          document.getElementById(images.id).style.display = "block"
                        }
                      }}
                      onTouchEnd={() => {
                        if (images.text === "VILLA IN BOSNIA") {
                          document.getElementById(images.id).style.display = "none"
                        } else if (images.text === "RESIDENCE 159-H") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LOUNGE AND DINING SPACE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "CORPORATE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "TEXTILE OFFICE DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "HOTEL INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN HOUSE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN VILLA") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "RESTAURANT INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LIVING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "BEDROOM INTERIOR DESIGN") {
                          document.getElementById(images.id).style.display = "block"
                        }
                        else if (images.text === "BEDROOM DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN LIVING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN LOUNGE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "DRAWING ROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "GYM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "ENTRANCE LOBBY") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "WALK-IN WARDROBE") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "HOTEL BEDROOM") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "A MODERN BEDROOM INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MASTER BATHROOM DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LUXURY APARTMENT DESIGN") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "MODERN HOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "LUXURY APARTMENT INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "SA HEADQUARTERS") {
                          document.getElementById(images.id).style.display = "none"
                        }
                        else if (images.text === "EXECUTIVE OFFICE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        } else if (images.text === "FARMHOUSE INTERIOR") {
                          document.getElementById(images.id).style.display = "none"
                        } else {
                          document.getElementById(images.id).style.display = "none"
                        }
                      }}

                    />
                    <div id={images.id}>
                      <h1>{images.text}</h1>
                    </div>
                  </div>

                </>
              )
            } else {

            }
          })




        }

      </div>

      <div id="projectDetails" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails').style.display = "none"

        }}>X</div>
        <img src={image01} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
      </div>




      <div id="projectDetails1" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails1').style.display = "none"

        }}>X</div>
        <img src={image4} alt="" />
        <img src={image5} alt="" />
        <img src={image6} alt="" />
        <img src={image7} alt="" />


      </div>








      <div id="projectDetails2" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails2').style.display = "none"

        }}>X</div>

        <img src={image8} alt="" />
        <img src={image9} alt="" />


      </div>



      <div id="projectDetails3" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails3').style.display = "none"

        }}>X</div>
        <img src={image10} alt="" />
        <img src={image11} alt="" />
        <img src={image12} alt="" />
        <img src={image13} alt="" />


      </div>




      <div id="projectDetails4" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails4').style.display = "none"

        }}>X</div>
        <img src={image14} alt="" />
        <img src={image15} alt="" />
        <img src={image16} alt="" />
        <img src={image17} alt="" />
        <img src={image18} alt="" />
        <img src={image19} alt="" />
        <img src={image20} alt="" />
        <img src={image21} alt="" />
        <img src={image22} alt="" />



      </div>


      <div id="projectDetails5" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails5').style.display = "none"

        }}>X</div>
        <img src={image23} alt="" />
        <img src={image24} alt="" />
        <img src={image25} alt="" />
        <img src={image26} alt="" />
        <img src={image27} alt="" />


      </div>






      <div id="projectDetails6" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails6').style.display = "none"

        }}>X</div>

          <video controls autoPlay muted loop>
            <source src={video} type='video/mp4' />
          </video>
          <img src={image28} alt="" />
          <img src={image29} alt="" />


      </div>




      <div id="projectDetails7" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails7').style.display = "none"

        }}>X</div>

        <img src={image30} alt="" />
        <img src={image31} alt="" />
        <img src={image32} alt="" />
        <img src={image33} alt="" />
        <img src={image34} alt="" />
        <img src={image35} alt="" />
        <img src={image36} alt="" />
        <img src={image37} alt="" />
      </div>



      <div id="projectDetails8" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails8').style.display = "none"

        }}>X</div>
        <img src={image1} alt="" />
      </div>



      <div id="projectDetails9" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails9').style.display = "none"

        }}>X</div>
        <img src={image38} alt="" />
        <img src={image39} alt="" />
        <img src={image40} alt="" />
        <img src={image41} alt="" />

      </div>




      <div id="projectDetails10" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails10').style.display = "none"

        }}>X</div>
        <img src={image42} alt="" />
        <img src={image43} alt="" />


      </div>


      <div id="projectDetails11" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails11').style.display = "none"

        }}>X</div>
        <img src={image44} alt="" />
        <img src={image45} alt="" />

      </div>

      <div id="projectDetails12" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails12').style.display = "none"

        }}>X</div>
        <img src={image46} alt="" />
        <img src={image47} alt="" />

      </div>



      <div id="projectDetails13" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails13').style.display = "none"

        }}>X</div>
        <img src={image48} alt="" />
        <img src={image49} alt="" />


      </div>




      <div id="projectDetails14" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails14').style.display = "none"

        }}>X</div>
        <img src={image50} alt="" />
        <img src={image51} alt="" />
        <img src={image52} alt="" />
        <img src={image53} alt="" />

      </div>



      <div id="projectDetails15" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails15').style.display = "none"

        }}>X</div>
        <img src={image54} alt="" />
        <img src={image55} alt="" />
        <img src={image56} alt="" />

      </div>



      <div id="projectDetails16" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails16').style.display = "none"

        }}>X</div>
        <img src={image57} alt="" />
        <img src={image58} alt="" />

      </div>


      <div id="projectDetails17" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails17').style.display = "none"

        }}>X</div>
        <img src={image59} alt="" />
        <img src={image60} alt="" />
        <img src={image61} alt="" />
        <img src={image62} alt="" />


      </div>



      <div id="projectDetails18" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails18').style.display = "none"

        }}>X</div>
        <img src={image63} alt="" />
        <img src={image64} alt="" />


      </div>



      <div id="projectDetails19" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails19').style.display = "none"

        }}>X</div>
        <img src={image65} alt="" />
        <img src={image66} alt="" />


      </div>


      <div id="projectDetails20" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails20').style.display = "none"

        }}>X</div>
        <img src={image67} alt="" />
        <img src={image68} alt="" />


      </div>


      <div id="projectDetails21" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails21').style.display = "none"

        }}>X</div>
        <img src={image69} alt="" />
        <img src={image70} alt="" />
        <img src={image71} alt="" />
        <img src={image72} alt="" />
        <img src={image73} alt="" />
        <img src={image74} alt="" />
        <img src={image75} alt="" />
        <img src={image76} alt="" />

      </div>

      <div id="projectDetails22" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails22').style.display = "none"

        }}>X</div>
        <img src={image77} alt="" />
        <img src={image78} alt="" />
        <img src={image79} alt="" />
        <img src={image80} alt="" />
        <img src={image81} alt="" />
        <img src={image82} alt="" />


      </div>



      <div id="projectDetails23" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails23').style.display = "none"

        }}>X</div>
        <img src={image83} alt="" />
        <img src={image84} alt="" />
        <img src={image85} alt="" />
        <img src={image86} alt="" />
        <img src={image87} alt="" />
        <img src={image88} alt="" />
        <img src={image89} alt="" />
        <img src={image90} alt="" />
        <img src={image91} alt="" />


      </div>


      <div id="projectDetails24" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails24').style.display = "none"

        }}>X</div>
        <img src={image92} alt="" />
        <img src={image93} alt="" />
        <img src={image94} alt="" />
      </div>



      <div id="projectDetails25" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails25').style.display = "none"

        }}>X</div>
        <img src={image95} alt="" />
        <img src={image96} alt="" />
        <img src={image97} alt="" />
        <img src={image98} alt="" />
        <img src={image99} alt="" />


      </div>


      <div id="projectDetails26" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails26').style.display = "none"

        }}>X</div>
        <img src={image100} alt="" />
        <img src={image101} alt="" />
        <img src={image102} alt="" />
        <img src={image103} alt="" />
        <img src={image104} alt="" />
        <img src={image105} alt="" />


      </div>


      <div id="projectDetails27" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails27').style.display = "none"

        }}>X</div>
        <img src={image106} alt="" />
        <img src={image107} alt="" />
        <img src={image108} alt="" />
        <img src={image109} alt="" />


      </div>

      <div id="projectDetails28" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails28').style.display = "none"

        }}>X</div>
        <img src={image110} alt="" />
        <img src={image111} alt="" />


      </div>


      <div id="projectDetails29" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails29').style.display = "none"

        }}>X</div>
        <img src={image112} alt="" />
        <img src={image113} alt="" />
        <img src={image114} alt="" />
        <img src={image115} alt="" />
        <img src={image116} alt="" />
        <img src={image117} alt="" />


      </div>




      <div id="projectDetails30" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails30').style.display = "none"

        }}>X</div>

      <img src={image118} alt="" />
      <img src={image119} alt="" />
      <img src={image120} alt="" />
      <img src={image121} alt="" />
      <img src={image122} alt="" />
      <img src={image123} alt="" />
      <img src={image124} alt="" />
      <img src={image125} alt="" />


      </div>

      <div id="projectDetails31" onClick={() => {
      }}>
        <div onClick={() => {
          document.getElementById('navbar').style.display = "block"
          document.getElementById('projectDetails31').style.display = "none"

        }}>X</div>
        <img src={image126} alt="" />
        <img src={image127} alt="" />
        <img src={image128} alt="" />
        <img src={image129} alt="" />
        <img src={image130} alt="" />


      </div>





      <footer onMouseOver={() => {
        document.getElementById('menuLinks').classList.remove('show')
      }}>










        <div className="social">
          <a href="https://www.facebook.com/profile.php?id=61551238487509" target='_blank' id='facebook'>

            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 12.05C19.9813 10.5255 19.5273 9.03809 18.6915 7.76295C17.8557 6.48781 16.673 5.47804 15.2826 4.85257C13.8921 4.2271 12.3519 4.01198 10.8433 4.23253C9.33473 4.45309 7.92057 5.10013 6.7674 6.09748C5.61422 7.09482 4.77005 8.40092 4.3343 9.86195C3.89856 11.323 3.88938 12.8781 4.30786 14.3442C4.72634 15.8103 5.55504 17.1262 6.69637 18.1371C7.83769 19.148 9.24412 19.8117 10.75 20.05V14.38H8.75001V12.05H10.75V10.28C10.7037 9.86846 10.7483 9.45175 10.8807 9.05931C11.0131 8.66687 11.23 8.30827 11.5161 8.00882C11.8022 7.70936 12.1505 7.47635 12.5365 7.32624C12.9225 7.17612 13.3368 7.11255 13.75 7.14003C14.3498 7.14824 14.9482 7.20173 15.54 7.30003V9.30003H14.54C14.3676 9.27828 14.1924 9.29556 14.0276 9.35059C13.8627 9.40562 13.7123 9.49699 13.5875 9.61795C13.4627 9.73891 13.3667 9.88637 13.3066 10.0494C13.2464 10.2125 13.2237 10.387 13.24 10.56V12.07H15.46L15.1 14.4H13.25V20C15.1399 19.7011 16.8601 18.7347 18.0985 17.2761C19.3369 15.8175 20.0115 13.9634 20 12.05Z" fill="#000000" />
            </svg>
          </a>
          <a href="https://www.instagram.com/atelier75.co/?igshid=MjEwN2IyYWYwYw%3D%3D" target='_blank' id='insta'>
            <svg width="800px" height="800px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">

              <g fill="none" fill-rule="evenodd">

                <path d="m0 0h32v32h-32z" />

                <path d="m23.6190476.13999994.2518496.00400082c4.4330352.13698982 7.9850489 3.77162206 7.9891029 8.23707871v15.23796813l-.0040009.2518496c-.1369898 4.4330352-3.771622 7.9850489-8.2370787 7.9891029h-15.23796812l-.25184954-.0040009c-4.43303524-.1369898-7.98504891-3.771622-7.9891029-8.2370787v-15.23796812l.00400082-.25184954c.13698982-4.43303524 3.77162206-7.98504891 8.23707871-7.9891029zm0 2.00571434h-15.23809522l-.2285857.00411224c-3.33773425.12028135-6.0066524 2.86402399-6.0066524 6.23112586v15.23809522l.00411224.2285857c.12028135 3.3377343 2.86402399 6.0066524 6.23112586 6.0066524h15.23809522l.2285857-.0041122c3.3377343-.1202814 6.0066524-2.864024 6.0066524-6.2311259v-15.23809522l-.0041122-.2285857c-.1202814-3.33773425-2.864024-6.0066524-6.2311259-6.0066524zm-7.6191758 4.85142851.2653486.00407526c4.8475552.14463612 8.7331682 4.11734025 8.7376368 8.99891015 0 4.9720125-4.0307165 9.002729-9.0028572 9.002729s-9.00285716-4.0307165-9.00285716-9.0028572c0-4.8833525 3.88804756-8.8586028 8.73741066-8.99901883zm.0001282 2.00571437c-3.8644153 0-6.99714284 3.13272754-6.99714284 6.99714284s3.13272754 6.9971428 6.99714284 6.9971428 6.9971428-3.1327275 6.9971428-6.9971428-3.1327275-6.99714284-6.9971428-6.99714284zm8.7619048-3.91047622c.7642569 0 1.3838095.61955263 1.3838095 1.38380953s-.6195526 1.38380953-1.3838095 1.38380953c-.764257 0-1.3838096-.61955263-1.3838096-1.38380953s.6195526-1.38380953 1.3838096-1.38380953z" fill="#000000" fill-rule="nonzero" />

              </g>

            </svg>
          </a>

          <a href="https://www.linkedin.com/in/saad-bashir-28b3ba116/" target='_blank' id='linkedin'>
            <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
              viewBox="-143 145 512 512" xmlSpace="preserve">
              <path d="M-143,145v512h512V145H-143z M41.4,508.1H-8.5V348.4h49.9V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7
              c0-15.8,12.1-27.7,30.5-27.7c18.4,0,29.7,11.9,30.1,27.7C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6
              c0-21.6-8.8-36.4-28.3-36.4c-14.9,0-23.2,10-27,19.6c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1
              c3.3-11,21.2-26.6,49.8-26.6c35.5,0,63.3,23,63.3,72.4V508.1z"/>
            </svg>
          </a>

        </div>
        <div className="whatsapp" id='wapp'>
          <a href="https://wa.me/923212556912" target='_blank'>
            <img src={whatsapp} alt="" className='whatsapp_btn blinking-image' />
          </a>
        </div>



      </footer>
    </>
  )
}
